html,
body {
  margin: 0;
  padding: 0;
}
body {
  font-size: 80%;
}

.max-width {
  max-width: 100%;
  margin: 0;
}

.off-canvas-menu,
#c1026,
#calchoose,
.printpage,
.header,
.teaser,
.page_title,
.breadcrumb,
.region-footer,
.pagebrowser,
p.found,
.previous-button,
.next-button {
  display: none;
}
h1 {
  margin-top: 0;
}
.tx-cal-controller .list-view abbr[title]:after {
  display: none;
}

.tx-cal-controller tr.days td.dayNum {
  padding: 0 .5rem;
  background: transparent;
}
.tx-cal-controller .month-large .vevent {
  padding: .1rem .5rem;
}

.tx-cal-controller .month-large .eventCountIs1, 
.tx-cal-controller .month-large .emptyWeek .empty {
  height: auto;
}


.tx-cal-controller div.default_catheader dl, .tx-cal-controller div.default_catheader_allday, .tx-cal-controller div.blue_catheader dl, .tx-cal-controller div.day, .tx-cal-controller div.blue_catheader_allday {
  border: 1px solid #666;
  border-left: .2rem dotted #666;
}


.tx-cal-controller .list-view {
  font-size: .875rem;
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-between; */
}

.tx-cal-controller .list-view dt {
  /* flex: 0 0 45%
  display: flex; */

}
.default_catheader_text {
}

.vevent {
  word-break: break-word;
  white-space: pre;
}